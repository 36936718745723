.shimmer__container{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.shimmer {
  height: 100%;
  width: 100%;
  background-color: rgb(237, 229, 229);
  display:inline-block;
  -webkit-mask:linear-gradient(-90deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

 @keyframes shimmer {
 100% {-webkit-mask-position:left}
}